<template>
    <div>
        <h4>{{title}}</h4>
        <p v-html="body"></p>
    </div>

</template>
<script>
    import { getFieldValues } from '@/utils/helpers';

    export default {
        name: "Einzelheiten",
        props: {
            content: {
                type: Object,
                required: true
            },
        },
        computed: {
            title(){
                var value = getFieldValues(this.content,'title');
                return value!==null?value:null;
            },
            body(){
                var value = getFieldValues(this.content,'body');
                return value!==null?value:null;
            },
        },
        methods:{
         getFieldValues, 
     }
 }
</script>